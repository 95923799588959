<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        Performance Analysis Manager
                        <v-spacer></v-spacer>
                        <add-button permission="grade-create" @action="updateId=null,selectedGrades=[],form.dialog = true,$refs.form ? $refs.form.reset():'' ">Create Performance Title </add-button>
                        <!--<refresh-button v-show="form.items.data.length" @action="get()"></refresh-button>-->
                    </v-card-title>
                    <v-data-table :headers="headers"
                                    :items="form.items.data" :search="search"
                                  :loading="form.loading"
                                  sort-by="name"
                                  :footer-props="footerProps"
                                  :options.sync="pagination"
                                  :server-items-length="form.items.meta.total"
                    >
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td class="text-xs-left">{{ item.name }}</td>
                                <td class="text-xs-left">
                                    <v-chip v-for="(gd,i) in item.grades" :key="i" @input="deleteGrade(gd)"
                                            small tiny>
                                        {{gd.name}}
                                    </v-chip>
                                </td>
                                <td class="text-xs-right">
                                    <edit-button permission="batch-update"
                                                @agree="editItem(item),form.edit(item)"/>
                                    <delete-button permission="marks-generate-bulk" @agree="form.delete(item.id)"/>
                                </td>
                            </tr>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>
                </v-card>
            </v-flex>

            <v-dialog v-model="form.dialog" persistent max-width="500px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Add/Update</span>
                    </v-card-title>
                    <v-card-text class="pa-1">
                        <v-form ref="form" @submit.prevent="store"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="store"
                                v-model="valid"
                                :lazy-validation="lazy">
                            <v-container grid-list-md>
                                <v-layout row wrap>
                                    <v-flex xs12>
                                        <!--{{form.grades}}-->
                                        <v-select :error-messages="form.errors.get('grades')" required
                                                  v-model="selectedGrades" :items="grades" label="Select Grades"
                                                  multiple
                                                  class="pt-0" outlined dense
                                                  :rules="[v => !!v || 'Grade is required']">
                                            <v-list-item slot="prepend-item" ripple @click="toggle">
                                                <v-list-item-action>
                                                    <v-icon :color="selectedGrades.length > 0 ? 'indigo darken-4' : ''">
                                                        {{ icon }}
                                                    </v-icon>
                                                </v-list-item-action>
                                                <v-list-item-title>Select All</v-list-item-title>
                                            </v-list-item>
                                            <v-divider slot="prepend-item" class="mt-2"></v-divider>
                                            <v-divider slot="append-item" class="mb-2"></v-divider>
                                        </v-select>
                                    </v-flex>
                                    <v-flex xs12>
                                        <div v-for="(p,i) in form.performances" :key="i">
                                            <v-text-field :label="i===0?'Performance Title':''" :append-icon="i===0?'':'close'"
                                                          @click:append="performanceRemove(i)"
                                                          @keyup="performanceIncrement"
                                                          autocomplete="off" class="pa-0" v-model="p.title"
                                                          name="name"
                                                          :error-messages="form.errors.get('performancess')" outlined dense
                                                          :rules="[v => !!v || 'Title Name is required']"/>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" outlined @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" outlined @click="validate">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex';
    import Form from '@/library/Form';


    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();
    import Mixins from '@/library/Mixins';

    export default {
        mixins: [Mixins],
        data: () => ({
            valid: true,
            lazy: false,
            form: new Form({
                name: '',
                grades: [],
                performances: [{title: ''}]
            }, '/api/exam/performance/marks'),
            search: null,
            updateId: null,
            selectedGrades: [],
            pagination: {
                rowsPerPage: 20
            },

            headers: [
                {text: 'SN', align: 'center', value: 'id', width: 50, sortable: false},
                {text: 'Name', align: 'left', value: 'name', sortable: false},
                {text: 'Grades', align: 'left', value: 'grade', sortable: false},
                {text: 'Action', align: 'right', sortable: false, width: 200}
            ],
            grades: [],
            batchStart: '',
            batchEnd: '',
            chartData: [],
            chartOptions: {
                title: {
                    text: 'Batch-Wise Students',
                    align: 'center'
                },
                legend: {
                    show: false,
                    floating: true,
                },
                labels: []
            }
        }),
        computed: {
            ...mapState(['batch']),
            icon() {
                if (this.selectAllGrades) return 'check_box';
                if (this.selectFewGrades) return 'indeterminate_check_box';
                return 'add_box'
            },
            selectAllGrades() {
                return this.selectedGrades.length === this.grades.length
            },
            selectFewGrades() {
                return this.selectedGrades.length > 0 && !this.selectAllGrades
            }
        },
        mounted() {
            this.getGrades();
        },
        watch: {
            'pagination': function (value) {
                this.get();
            }
        },
        methods: {
            validate() {
                if (this.$refs.form.validate()) {
                    this.store()
                }
            },
            editItem(item) {
                this.updateId = item.id;
                this.form.performances = [{title: item.name}];
                this.selectedGrades = item.grades.map(function (gra) {
                    return gra.id;
                });
            },
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&');
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                })
            },
            getGrades() {
                this.$rest.get('/api/grades?rowsPerPage=25&sortBy=rank&descending=false').then(({data}) => {
                    this.grades = data.data.map(item => {
                        return {text: item.name, value: item.id};
                    });
                })
            },
            store() {
                if (this.updateId !== null) {
                    this.form.name = this.form.performances[0].title;
                }
                this.form.grades = this.selectedGrades.join(',');
                this.form.store().then(() => {
                });
            },
            performanceIncrement() {
                let lastEle = this.form.performances.slice().pop();
                if (lastEle.title.length > 3 && this.updateId === null) {
                    this.form.performances.push({title: ''});
                }
            },
            performanceRemove(i) {
                if (i !== 0) {
                    this.form.performances.splice(i, 1);
                }
            },
            deleteGrade(grade) {
            },
            toggle() {
                this.$nextTick(() => {
                    if (this.selectAllGrades) {
                        this.selectedGrades = []
                    } else {
                        this.selectedGrades = this.grades.map(grade => {
                            return grade.value;
                        })
                    }
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .marks_entry {
        border: 1px solid #ccc;
        font-weight: bold;
        border-radius: 3px;
        width: 142px;
        height: 25px !important;
        text-align: center !important;
    }

    input[disabled], select[disabled] {
        background-color: #eee;
        color: #ccc;
        cursor: not-allowed;
    }

    .theme--light.v-select .v-select__selections {
        margin-top: -27px !important;
        margin-left: 8px !important;
    }

    input:focus {
        outline: none;
    }

    select:focus {
        outline: none;
    }

</style>